<template>
  <div>
    <form action="#" class="form mt-5" @submit.prevent.stop="resetPassword">
      <div v-if="message"
           :class="{'alert-primary' : message?.type === 'success', 'alert-danger': message?.type === 'error'}"
           class="alert border-0 rounded-0" role="alert" v-text="message?.text"></div>

      <div class="mb-3">
        <input v-model="form.password" :disabled="loading" aria-label="Password Input"
               class="form-control es-bg-secondary border-0 p-3 text-center" placeholder="Password" type="password">
        <p v-if="errors.password" class="text-danger mt-1" v-text="errors.password"/>
      </div>
      <div class="mb-3">
        <input v-model="form.password_confirmation" :disabled="loading" aria-label="Password confirmation Input"
               class="form-control es-bg-secondary border-0 p-3 text-center" placeholder="Password Confirmation"
               type="password">
        <p v-if="errors.password_confirmation" class="text-danger mt-1" v-text="errors.password_confirmation"/>
      </div>

      <button :disabled="loading" class="w-100 btn btn-primary btn-primary-es rounded-0 p-3 position-relative"
              type="submit">
        <Spinner :loading="loading" color="white"/>
        <span v-show="!loading">Reset Password</span>
      </button>
    </form>

    <div class="d-flex flex-column gap-2 mt-4">
      <div class="text-center">
        <RouterLink :to="{name: 'Login'}" v-text="'Back to login'"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Spinner from '@core/components/loading/Spinner.vue';
import notify from '@core/utils/notify.js';
import {log} from '@core/utils/dev.js';

export default {
  name: 'ResetPassword',
  components: {Spinner},
  data() {
    return {
      form: {
        password: '',
        password_confirmation: '',
      },
      loading: false,
      message: null,
      errors: {
        email: '',
      },
    };
  },
  computed: {
    payload() {
      return {
        token: this.$route.params.token,
        email: this.$route.query.email,
        ...this.form,
      };
    },
  },
  methods: {
    async resetPassword() {
      if (this.loading) {
        return;
      }

      this.resetErrors();

      this.loading = true;

      try {
        const {data: {message}} = await axios.request({
          method: 'PUT',
          data: this.payload,
          url: '/password',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        });

        if (typeof message === 'string' && message.trim().length > 0) {
          this.message = {type: 'success', text: message};
          this.form.password = '';
          this.form.password_confirmation = '';
        }
      } catch (e) {
        log(e);

        if (!e.response) {
          notify.error('Something went wrong!');
          return;
        }

        const {errors, message} = e.response.data ?? {};

        if (typeof message === 'string' && message.trim().length > 0) {
          this.message = {
            type: 'error',
            text: message,
          };
        }

        if (errors && typeof errors === 'object') {
          this.errors = Object.fromEntries(
            Object.keys(errors).map(key => [key, Array.isArray(errors[key]) ? errors[key][0] : errors[key]]));
        }

      } finally {
        this.loading = false;
      }
    },
    resetErrors() {
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = '';
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.es-bg-secondary {
  background-color: #f1f1f1;
}

.btn-primary-es {
  background: #3152a3;
  border-color: #3152a3;
}
</style>

<template>
  <div>
    <form action="#" class="form mt-5" @submit.prevent.stop="acceptInvite">
      <div class="mb-3">
        <input v-model="form.password" :disabled="loading" aria-label="Password Input"
               class="form-control es-bg-secondary border-0 p-3 text-center" placeholder="Password" type="password">
        <p v-if="errors.password" class="text-danger mt-1" v-text="errors.password"/>
      </div>
      <div class="mb-3">
        <input v-model="form.password_confirmation" :disabled="loading" aria-label="Password Confirmation Input"
               class="form-control es-bg-secondary border-0 p-3 text-center" placeholder="Password Confirmation"
               type="password">
        <p v-if="errors.password_confirmation" class="text-danger mt-1" v-text="errors.password_confirmation"/>
      </div>

      <button :disabled="loading" class="w-100 btn btn-primary btn-primary-es rounded-0 p-3 position-relative"
              type="submit">
        <Spinner :loading="loading" color="white"/>
        <span v-show="!loading">Accept Invitation</span>
      </button>
    </form>

    <div class="d-flex flex-column gap-2 mt-4">
      <div class="text-center">
        <RouterLink :to="{name: 'Login'}" v-text="'Login'"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Spinner from '@core/components/loading/Spinner.vue';
import notify from '@core/utils/notify.js';

export default {
  name: 'Invitation',
  components: {Spinner},
  data() {
    return {
      form: {
        password: '',
        password_confirmation: '',
      },
      loading: false,
      errors: {
        password: '',
        password_confirmation: '',
      },
    };
  },
  methods: {
    async acceptInvite() {
      if (this.loading) {
        return;
      }

      this.resetErrors();

      this.loading = true;

      try {
        const {data: {message, redirect}} = await axios.request({
          method: 'POST',
          data: this.form,
          url: `/guest/invitation/${this.$route.params.token}/accept`,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        });

        message && notify.success(message);

        if (redirect) {
          window.location = redirect;
        }
      } catch (e) {
        console.dir(e);
        if (!e.response) {
          notify.error('Something went wrong!');
          return;
        }

        const {errors, message, redirect} = e.response.data ?? {};

        message && notify.error(message);

        if (redirect) {
          window.location = redirect;
          return;
        }

        if (!!errors && typeof errors === 'object') {
          Object.keys(errors).forEach(key => {
            this.errors[key] = Array.isArray(errors[key]) ?
              errors[key][0] :
              (typeof errors[key] === 'string' ? errors[key] : '');
          });
        }

      } finally {
        this.loading = false;
      }
    },
    resetErrors() {
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = '';
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.es-bg-secondary {
  background-color: #f1f1f1;
}

.btn-primary-es {
  background: #3152a3;
  border-color: #3152a3;
}
</style>

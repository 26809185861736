import {createApp} from 'vue';
import GuestApp from '@guest/GuestApp.vue';
import router from '@guest/router.js';
import store from '@guest/store.js';
import notify from '@core/utils/notify.js';

const app = createApp(GuestApp);

app.use(router).
  use(store).
  mount('#guest-app');

app.config.globalProperties.$notify = notify;

<template>
  <div>
    <form action="#" class="form mt-5" @submit.prevent.stop="forgotPassword">
      <div v-if="message" class="alert alert-primary border-0 rounded-0" role="alert" v-text="message"></div>
      <div class="mb-3">
        <input v-model="form.email" :disabled="loading" aria-label="Email Input"
               class="form-control es-bg-secondary border-0 p-3 text-center" placeholder="Email" type="email">
        <p v-if="errors.email" class="text-danger mt-1" v-text="errors.email"/>
      </div>

      <button :disabled="loading" class="w-100 btn btn-primary btn-primary-es rounded-0 p-3 position-relative"
              type="submit">
        <Spinner :loading="loading" color="white"/>
        <span v-show="!loading">Forgot Password</span>
      </button>
    </form>

    <div class="d-flex flex-column gap-2 mt-4">
      <div class="text-center">
        <RouterLink :to="{name: 'Login'}" v-text="'Back to login'"/>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@core/components/loading/Spinner.vue';
import axios from 'axios';
import notify from '@core/utils/notify.js';
import {log} from '@core/utils/dev.js';

export default {
  name: 'ForgotPassword',
  components: {Spinner},
  data() {
    return {
      form: {
        email: '',
      },
      loading: false,
      message: null,
      errors: {
        email: '',
      },
    };
  },
  methods: {
    async forgotPassword() {
      if (this.loading) {
        return;
      }

      this.resetErrors();

      this.loading = true;

      try {
        const {data: {message}} = await axios.request({
          method: 'POST',
          data: this.form,
          url: '/forgot-password',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        });

        if (message) {
          this.message = message;
          this.form.email = '';
        }
      } catch (e) {
        log(e);

        if (!e.response) {
          notify.error('Something went wrong!');
          return;
        }

        const {errors, message} = e.response.data ?? {};

        message && notify.error(message);

        this.errors.email = Array.isArray(errors?.email) ? errors?.email[0] : '';
        this.errors.password = Array.isArray(errors?.password) ? errors?.password[0] : '';

      } finally {
        this.loading = false;
      }
    },
    resetErrors() {
      this.errors.email = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.es-bg-secondary {
  background-color: #f1f1f1;
}

.btn-primary-es {
  background: #3152a3;
  border-color: #3152a3;
}
</style>

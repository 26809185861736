<template>
  <form action="#" class="form mt-5" @submit.prevent.stop="sendVerificationEmail">
    <div v-if="!sent" class="alert alert-warning border-0 rounded-0" role="alert">
      You need to verify your account before being able to use the system. Click on the send
      to receive account verification email. Open the received email and click on on the verify link.
    </div>

    <div v-if="sent" class="alert alert-success border-0 rounded-0" role="alert">
      Check your inbox you should be receiving verification link at any moment now.
    </div>

    <button :disabled="loading" class="w-100 btn btn-primary btn-primary-es rounded-0 p-3 position-relative"
            type="submit">
      <Spinner :loading="loading" color="white"/>
      <span v-show="!loading">Request Verification Link</span>
    </button>
  </form>
</template>

<script>
import axios from 'axios';
import Spinner from '@core/components/loading/Spinner.vue';
import {log} from '@core/utils/dev.js';
import notify from '@core/utils/notify.js';

export default {
  name: 'RequestVerificationLink',
  components: {Spinner},
  data() {
    return {
      loading: false,
      sent: false,
    };
  },
  methods: {
    async sendVerificationEmail() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        const {data: {message}} = await axios.request({
          method: 'POST',
          url: '/email/verification-notification',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        });

        if (typeof message === 'string' && message.trim().length > 0) {
          this.message = {type: 'success', text: message};
          this.sent = true;
        }
      } catch (e) {
        log(e);

        if (!e.response) {
          notify.error('Something went wrong!');
          return;
        }

        const {message} = e.response.data ?? {};

        if (typeof message === 'string' && message.trim().length > 0) {
          this.message = {
            type: 'error',
            text: message,
          };
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
